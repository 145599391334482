import React from "react";

import Layout from "../components/layout/layout.component";
import { StyledCenter } from "../utils/styledComponents/center.style";
import { StyledBoldTitle } from "../utils/styledComponents/boldTitle.style";
import SEO from "../components/seo.component";
import { LANG } from "../lang/lang";

const NotFound = () => {
  return (
    <Layout>
      <SEO title={LANG.PL.notFound} />
      <StyledCenter
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <StyledBoldTitle>{LANG.PL.notFound}</StyledBoldTitle>
      </StyledCenter>
    </Layout>
  );
};

export default NotFound;
